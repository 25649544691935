import { graphql } from 'gatsby';

import { Page } from '../components/layout';
import { Markdown } from '../components/utils';

import View from './locations/view';
import GoogleMap from './locations/google-map';
import IVBWidget from './locations/ivb-widget';

import * as styles from './locations.module.scss';

const Locations = ({
	data: {
		markdownRemark: {
			frontmatter: { photos, traffic_information },
			fields: { slug },
			html,
		},
	},
}) => (
	<Page slug={slug}>
		<div className={styles.view}>
			<View photos={photos} />
		</div>

		<div className={styles.html}>
			<Markdown content={traffic_information} />
		</div>

		<div className={styles.widget}>
			<IVBWidget />
		</div>

		<GoogleMap />
	</Page>
);

export const locationsQuery = graphql`
	query locationsQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			frontmatter {
				photos {
					label
					viewId
					image {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
				traffic_information
			}
			fields {
				slug
			}
			html
		}
	}
`;

export default Locations;
