import { useRef, useEffect, forwardRef } from 'react';
import { createPortal } from 'react-dom';

import * as styles from './portal.module.scss';

const Portal = forwardRef((props, ref) => {
	const target = usePortal();

	return createPortal(
		<div ref={ref} {...props}>
			{props.children}
		</div>,
		target
	);
});

export default Portal;

const usePortal = (props, ref) => {
	const targetRef = useRef(null);

	useEffect(
		() => {
			document.body.appendChild(targetRef.current);

			return () => targetRef.current.remove();
		},
		[
			/* apply only on mount/unmount */
		]
	);

	// assert that the dom node for this portal is only created during initial render:
	// https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
	const createTarget = () => {
		if (targetRef.current === null) {
			targetRef.current = document.createElement('div');
			targetRef.current.setAttribute('class', styles.wrapper);
		}

		return targetRef.current;
	};

	return createTarget();
};
