const GoogleMap = (props) => (
	<iframe
		width="100%"
		height="400"
		frameBorder="0"
		allowFullScreen={true}
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2707.4889601157665!2d11.391680815976578!3d47.265695519457616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479d6bfb5b2edc3f%3A0xa8ea57a84d2e8c14!2sRathausGalerien!5e0!3m2!1sde!2sat!4v1562579709686!5m2!1sde!2sat"
		{...props}></iframe>
);

export default GoogleMap;
