import { useEffect, useRef, useContext } from 'react';

import LocaleContext from '../../contexts/locale';

import * as styles from './ivb-widget.module.scss';

const WIDGET_CUSTOMER = 'vs_ivb';
const WIDGET_INIT_EVT = '/widgets/init';
const WIDGET_BASE_URL =
	'https://fahrplan.ivb.at/webapp/staticfiles/hafas-widget-core.1.0.0.js';

const IVBWidget = () => {
	const widgetRef = useRef(null);
	const { locale } = useContext(LocaleContext);

	const getLanguage = () => {
		switch (locale) {
			case 'de':
				return 'de_DE';

			default:
				return 'en_GB';
		}
	};

	useEffect(() => {
		// noop if not being in browser mode
		if (typeof window === 'undefined') {
			return;
		}

		// reinitialize widget if loaded before
		const { Hafas: WidgetAPI } = window;
		if (typeof WidgetAPI !== 'undefined') {
			const {
				Config: { Widgets },
			} = WidgetAPI;

			// update `container` = dom element
			const [widget] = Widgets;
			widget.container = widgetRef.current;

			// reinitialize widget with new dom
			WidgetAPI.ps.pub(WIDGET_INIT_EVT, Widgets);

			return;
		}

		// load widget plugin and initialize it
		const src = `${WIDGET_BASE_URL}?L=${WIDGET_CUSTOMER}&language=${getLanguage()}`;
		const script = document.createElement('script');
		script.async = true;
		script.src = src;

		document.body.appendChild(script);
	}, []);

	return (
		<div
			ref={widgetRef}
			className={styles.wrapper}
			data-hfs-widget="true"
			data-hfs-widget-tp="true"
			data-hfs-widget-tp-postform="newtab"
			data-hfs-widget-tp-arralias="RathausGalerien"></div>
	);
};

export default IVBWidget;
