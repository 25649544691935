// extracted by mini-css-extract-plugin
export var caption = "lightbox-module--caption--ew0wE";
export var close = "lightbox-module--close--v1N4e";
export var icon = "lightbox-module--icon--CHTzR";
export var image = "lightbox-module--image--llkLU";
export var link = "lightbox-module--link--k6M-u";
export var loader = "lightbox-module--loader---iarj";
export var modal = "lightbox-module--modal--MW8Fp";
export var next = "lightbox-module--next--++22q";
export var prev = "lightbox-module--prev--RAe1m";
export var wrapper = "lightbox-module--wrapper--LOtyh";