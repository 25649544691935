import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

const all = [];
const uuidMap = {};
const localeMap = {};

export const useCategoryByUuid = (uuid) => {
	invokeQueryAndMapData();

	if (uuid in uuidMap) {
		return uuidMap[uuid];
	}

	return null;
};

const invokeQueryAndMapData = () => {
	const {
		allMarkdownRemark: {
			edges: categories
		}
	} = useStaticQuery(query);

	const {
		currentLocale
	} = useContext(LocaleContext);

	const mapItem = ({
		node: {
			fields,
			frontmatter: data
		}
	}) => ({
		...data,
		...fields
	});

	if (all.length === 0) {
		for (let category of categories) {
			const item = mapItem(category);
			const { uuid, locale } = item;
			
			localeMap[locale] = localeMap[locale] || [];
			localeMap[locale].push(item);
			uuidMap[uuid] = item;
			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
};

export default invokeQueryAndMapData;

const query = graphql`
	query useCategories {
		allMarkdownRemark(
	    	filter: {
		        frontmatter: {
		          type: {
		            eq: "category"
		          }
		        }
		  	}
		  	sort: {
		  		fields: frontmatter___name
		  	}
	  	)
	  	{
			edges {
				node {
					frontmatter {
						name
						uuid
					}
					fields {
						locale
					}
				}
			}
		}
	}
`;