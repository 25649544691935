import { forwardRef } from 'react';

import Portal from './portal';
import * as styles from './tooltip.module.scss';

const Tooltip = forwardRef(({ children }, ref) => (
	<Portal ref={ref} className={styles.wrapper}>
		{children}
	</Portal>
));

export default Tooltip;
