import { forwardRef, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import Portal from './portal';
import { CloseIcon, LoaderIcon, ArrowLeftIcon, ArrowRightIcon } from './icons';

import * as styles from './lightbox.module.scss';

const LightBox = forwardRef(
	({ image, caption, hasPrev, onPrev, hasNext, onNext, onClose }, ref) => {
		const [style, setStyle] = useState({
			height: '100%',
			width: '100%',
		});

		const contentRef = useRef(null);

		useEffect(
			() => {
				const handleResize = () => {
					const { current: content } = contentRef;
					if (content === null) {
						return;
					}

					const { offsetHeight, offsetWidth } = content;

					const {
						childImageSharp: {
							gatsbyImageData: { aspectRatio, presentationWidth, presentationHeight },
						},
					} = image;

					const style =
						aspectRatio >= 1
							? {
									// landscape photos
									maxWidth: Math.min(offsetWidth, presentationWidth),
									maxHeight: Math.min(
										offsetWidth / aspectRatio,
										offsetHeight,
										presentationHeight
									),
							  }
							: {
									// portrait photos
									maxWidth: Math.min(
										offsetHeight * aspectRatio,
										offsetWidth,
										presentationWidth
									),
									maxHeight: Math.min(offsetHeight, presentationHeight),
							  };

					setStyle(style);
				};

				window.addEventListener('resize', handleResize, false);
				handleResize(); // trigger after initial rendering once

				return () => {
					window.addEventListener('resize', handleResize, false);
				};
			},
			[
				/* only apply on mount/ummount */
			]
		);

		useEffect(
			() => {
				const handleKeydown = (event) => {
					switch (event.keyCode) {
						case 27: {
							this.props.onClose(event);
							break;
						}

						case 37: {
							this.props.onPrev(event);
							break;
						}

						case 39: {
							this.props.onNext(event);
							break;
						}

						default:
					}
				};

				document.addEventListener('keydown', handleKeydown, false);

				return () => {
					document.removeEventListener('keydown', handleKeydown, false);
				};
			},
			[
				/* only apply on mount/ummount */
			]
		);

		return (
			<Portal ref={ref}>
				<div role="button" tabIndex="-1" onClick={onClose} className={styles.modal}>
					<button onClick={onClose} className={styles.close}>
						<CloseIcon className={styles.icon} />
					</button>

					{hasPrev && (
						<button onClick={onPrev} className={styles.prev}>
							<ArrowLeftIcon className={styles.icon} />
						</button>
					)}

					<div ref={contentRef} className={styles.wrapper}>
						<a onClick={onNext} href="javascript:void(0)" className={styles.link}>
							<GatsbyImage
								alt={caption}
								style={style}
								className={styles.image}
								image={image.childImageSharp.gatsbyImageData}
							/>
						</a>

						{caption && <div className={styles.caption}>{caption}</div>}

						<LoaderIcon className={styles.loader} />
					</div>

					{hasNext && (
						<button onClick={onNext} className={styles.next}>
							<ArrowRightIcon className={styles.icon} />
						</button>
					)}
				</div>
			</Portal>
		);
	}
);

LightBox.defaultProps = {
	image: null,
	caption: null,
	hasNext: false,
	hasPrev: false,
	onNext: () => {},
	onPrev: () => {},
	onClose: () => {},
};

LightBox.propTypes = {
	image: PropTypes.object.isRequired,
	caption: PropTypes.string,
	onNext: PropTypes.func.isRequired,
	onPrev: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	hasNext: PropTypes.bool.isRequired,
	hasPrev: PropTypes.bool.isRequired,
};

export default LightBox;
