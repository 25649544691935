import { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { SvgLoader, SvgProxy } from 'react-svgmt';

import Tooltip from '../../components/tooltip';
import LightBox from '../../components/lightbox';
import useLocations from '../../hooks/use-locations';
import { useCategoryByUuid } from '../../hooks/use-categories';

import * as styles from './view.module.scss';

const SVG_PATH = '/misc/view.svg';

//
// WRAPPER
//
const Wrapper = ({ photos }) => {
	const [activeLocation, setActiveLocation] = useState(null);
	const [currentEvent, setCurrentEvent] = useState(null);
	const [activePhoto, setActivePhoto] = useState(null);
	const tooltipRef = useRef(null);

	useEffect(() => {
		if (currentEvent === null) {
			return;
		}

		const { changedTouches, clientX, clientY } = currentEvent;
		const { current: tooltip } = tooltipRef;
		if (tooltip === null) {
			return;
		}

		const left = Array.isArray(changedTouches) ? changedTouches[0].clientX : clientX;

		const top = Array.isArray(changedTouches) ? changedTouches[0].clientY : clientY;

		tooltip.style.left = `${left}px`;
		tooltip.style.top = `${top}px`;
	}, [currentEvent]);

	const onLocationEnter = ({ event, location }) => {
		setActiveLocation(location);
		setCurrentEvent(event);
	};

	const onLocationMove = ({ event }) => {
		setCurrentEvent(event);
	};

	const onLocationLeave = () => {
		setActiveLocation(null);
		setCurrentEvent(null);
	};

	const onLocationClick = ({ location: { slug } }) => {
		navigate(slug);
	};

	const onPhotoClick = ({ photo }) => {
		setActivePhoto(photo);
	};

	const onPhotoClose = () => {
		setActivePhoto(null);
	};

	return (
		<Fragment>
			<SvgLoader path={SVG_PATH}>
				{useLocations().map((location, key) => (
					<Location
						key={key}
						location={location}
						onMove={onLocationMove}
						onClick={onLocationClick}
						onEnter={onLocationEnter}
						onLeave={onLocationLeave}
					/>
				))}

				{photos.map((photo, key) => (
					<Photo key={key} photo={photo} onClick={onPhotoClick} />
				))}
			</SvgLoader>

			{activeLocation && (
				<Tooltip ref={tooltipRef}>
					<div className={styles.tooltipTitle}>{activeLocation.name}</div>
					<div className={styles.tooltipContent}>
						{activeLocation.categories.map((uuid, key) => (
							<Category key={key} uuid={uuid} />
						))}
					</div>
				</Tooltip>
			)}

			{activePhoto && (
				<LightBox
					onClose={onPhotoClose}
					image={activePhoto.image}
					caption={activePhoto.label}
				/>
			)}
		</Fragment>
	);
};

Wrapper.defaultProps = {
	photos: [],
};

Wrapper.propTypes = {
	photos: PropTypes.arrayOf(
		PropTypes.shape({
			viewId: PropTypes.string.isRequired,
			image: PropTypes.object.isRequired,
			label: PropTypes.string,
		})
	).isRequired,
};

export default Wrapper;

//
// LOCATION
//
export const Location = ({ onEnter, onLeave, onMove, onClick, location }) => {
	const [isActive, setIsActive] = useState(false);
	const [time, setTime] = useState(0);

	const getTime = () => new Date().getTime();

	const onPointerEnter = (event) => {
		setTime(getTime());

		event.preventDefault();
		setIsActive(true);
		onEnter({
			location,
			event,
		});
	};

	const onPointerMove = (event) => {
		event.preventDefault();
		onMove({
			location,
			event,
		});
	};

	const onPointerLeave = (event) => {
		event.preventDefault();
		setIsActive(false);
		onLeave({
			location,
			event,
		});

		// `preventDefault()` avoids click to
		// be triggered - invoke manually if
		// it's a "normal" click (< 300ms):
		switch (event.type) {
			case 'touchend': {
				if (getTime() - time < 300) {
					onClick({
						location,
						event,
					});
				}

				break;
			}

			default:
		}
	};

	const onPointerClick = (event) => {
		event.preventDefault();
		onClick({
			location,
			event,
		});
	};

	return (
		<SvgProxy
			onClick={onPointerClick}
			onMouseMove={onPointerMove}
			onTouchMove={onPointerMove}
			onTouchEnd={onPointerLeave}
			onMouseOut={onPointerLeave}
			onMouseOver={onPointerEnter}
			onTouchStart={onPointerEnter}
			selector={`#${location.viewId}`}
			class={
				!isActive ? `${styles.location}` : `${styles.location} ${styles.locationIsActive}`
			}
		/>
	);
};

Location.defaultProps = {
	location: null,
	onMove: (/* event */) => {},
	onClick: (/* event */) => {},
	onEnter: (/* event */) => {},
	onLeave: (/* event */) => {},
};

Location.propTypes = {
	onMove: PropTypes.func,
	onClick: PropTypes.func,
	onEnter: PropTypes.func,
	onLeave: PropTypes.func,
	location: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		viewId: PropTypes.string,
	}),
};

//
// PHOTO
//
const Photo = ({ photo, onClick }) => {
	const onPointerClick = (event) => {
		event.preventDefault();
		onClick({
			photo,
			event,
		});
	};

	return (
		<SvgProxy
			onClick={onPointerClick}
			selector={`#${photo.viewId}`}
			class={styles.photo}
		/>
	);
};

Photo.defaultProps = {
	photo: null,
	onClick: (/* photo */) => {},
};

Photo.propTypes = {
	onClick: PropTypes.func,
	photo: PropTypes.shape({
		image: PropTypes.object.isRequired,
		viewId: PropTypes.string,
	}).isRequired,
};

//
// CATEGORY
//
const Category = ({ uuid }) => {
	// simple helper to call hook outside of loop:
	// https://reactjs.org/docs/hooks-rules.html
	const category = useCategoryByUuid(uuid);

	return category && <div className={styles.tooltipCategory}>{category.name}</div>;
};

Category.defaultProps = {
	uuid: null,
};

Category.propTypes = {
	uuid: PropTypes.string.isRequired,
};
